var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"9c6dc92abe4063cbe67e868e7e2238d05256f1eb"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/docs";

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://f98e2fdc25d64207b88d94636325dd2d@o1224454.ingest.sentry.io/6509917",
  tracesSampleRate: 0,
  beforeSend(event) {
    try {
      if (event.exception.values[0].stacktrace.frames[0].filename === `<anonymous>`) {
        return null;
      }
    } catch (e) {}

    return event;
  }
});
